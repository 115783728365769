import React from 'react';

/** This component, is loading view.
 *  Sole reason why it exists in project,
 *  is that localization library needs it.
 */
const Loader = () => (
    <div className="App">
      <div>loading...</div>
    </div>
);

export default Loader;
  