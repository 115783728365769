import React from 'react';

import { Link } from 'react-router-dom';

import EmptyFitnessImg from '../img/emptyfitness.jpg';
import { useTranslation } from 'react-i18next';


/**This component is an error page, should be displayed when anything goes wrong.*/
const ErrorPage = () => {

  const { t } = useTranslation();
  
  return (
      <div className="sr-root">
        <div className="sr-main">
          <header className="sr-header">
            <div className="sr-header__logo"></div>
            <h4>{t("powered by echino")}</h4>
          </header>
          <div className="sr-payment-summary completed-view">
            <h1>{t("Missing parameters or general error")}</h1>
            <Link to="/">{t("Back to")} {t("registration")}</Link>
            <h6>{"Parameters required for integration: apikey and subscription guid"}</h6>
          </div>
        </div>
        <div className="sr-content">
          <div className="fitness-image-stack">
            <img
              alt=""
              src={EmptyFitnessImg}
              width="180"
              height="140"
            />
          </div>
        </div>
      </div>
  );
};

export default ErrorPage;
