import React from 'react';
import EmptyFitnessImg from '../img/emptyfitness.jpg';
import ManAndWomanImg from '../img/manandwoman.jpg';
import StretchingImg from '../img/stretching.jpg';
import { useTranslation } from 'react-i18next';
const queryString = require('query-string');

const urlParams = queryString.parse(window.location.search);

/**This component, is webpage, that is to be displayed when stripe checkpoint redirects back AND payment is completed AND there is no callback_success url param
 * If redirect url param is provided, page contains it as a link, if is not, page contains link to (???) TODO determine which link is needed 
 */
const Success = () => {
 // const location = useLocation();
 // const sessionId = location.search.replace('?session_id=', '');
  // const callout = <div className="sr-callout">
  //                  <pre><h6>Checkout ID: {sessionId}</h6></pre>
  //                 </div>
  const callout =""

  const { t } = useTranslation();

  const redirectLink = urlParams.redirect ? <a href={urlParams.redirect}> {t("Back to")} {urlParams.redirect}</a>
                                          : <a href="http://abofits.com">{t("Back to")} abofits.com</a>
  //TODO
  return (
    <div className="sr-root">
      <div className="sr-main">
        <header className="sr-header">
          <div className="sr-header__logo"></div>
          <h4>{t("powered by echino")}</h4>
        </header>
        <div className="sr-payment-summary completed-view">
          <h1>{t("Your payment succeeded")}</h1>
        </div>
        <div className="sr-section completed-view">
          {callout}
          {redirectLink}
        </div>
      </div>
      <div className="sr-content">
        <div className="fitness-image-stack">
        <img
            alt=""
            src={ManAndWomanImg}
            width="180"
            height="140"
          />
          <img
            alt=""
            src={EmptyFitnessImg}
            width="180"
            height="140"
          />
          <img
            alt=""
            src={StretchingImg}
            width="180"
            height="140"
          />
        </div>
      </div>
    </div>
  );
};

export default Success;
