import React from 'react';

import EmptyFitnessImg from '../img/emptyfitness.jpg';
import ManAndWomanImg from '../img/manandwoman.jpg';
import StretchingImg from '../img/stretching.jpg';
import { useTranslation } from 'react-i18next';
const queryString = require('query-string');

const urlParams = queryString.parse(window.location.search);


/**This component, is webpage, that is to be displayed when stripe checkpoint redirects back AND payment is not completed AND there is no callback_failed url param
 * If redirect url param is provided, page contains it as a link, if is not page contains link to (???) TODO determine which link is needed 
 */
const Canceled = () => {
  const { t } = useTranslation();
  
  const redirectLink = urlParams.redirect ? <a href={urlParams.redirect}> {t("Back to")} {urlParams.redirect}</a>
                                          : <a href="http://abofits.com">{t("Back to")} abofits.com</a>
  
  return (
    <div className="sr-root">
      <div className="sr-main">
        <header className="sr-header">
          <div className="sr-header__logo"></div>
          <h4>powered by echino</h4>
        </header>
        <div className="sr-payment-summary completed-view">
          <h1>{t("Your payment was canceled")}</h1>
          {redirectLink}
        </div>
      </div>
      <div className="sr-content">
        <div className="fitness-image-stack">
        <img
            alt=""
            src={StretchingImg}
            width="180"
            height="140"
          />
          <img
            alt=""
            src={ManAndWomanImg}
            width="180"
            height="140"
          />
          <img
            alt=""
            src={EmptyFitnessImg}
            width="180"
            height="140"
          />
        </div>
      </div>
    </div>
  );
};

export default Canceled;
