import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route,Redirect} from 'react-router-dom';

import {Checkout} from './components/CheckoutV2';
import Success from './components/Success';
import Canceled from './components/Canceled';
import ErrorPage from './components/Error';

import './css/normalize.css';
import './css/global.css';
import './i18n';
import i18n from './i18n';
import Loader from './components/Loader'

const queryString = require('query-string');
const urlParams = queryString.parse(window.location.search);

const paramsNotAvailable  = urlParams.subscription===undefined;

const lang = urlParams.lang;

/**This component, is root of the Application, it defines routes for webpages. */
function App() {
  
  i18n.changeLanguage(lang)
  
  return (
    <Suspense fallback={<Loader />}>
    <Router>
      <Switch>
        <Route path="/success">
          <Success />
        </Route>
        <Route path="/canceled">
          <Canceled />
        </Route>
        <Route path="/error">
          <ErrorPage/>
        </Route>
        <Route  path="/"
          render={() => (
            paramsNotAvailable ? (
              <Redirect to="/error"/>
              ) : (
              <Checkout/>
            )
          )
              }/>
      </Switch>
    </Router>
    </Suspense>
  );
}

  ReactDOM.render(<App />, document.getElementById('root'));
