import React from 'react';

import { loadStripe } from '@stripe/stripe-js';

import { withTranslation } from 'react-i18next';
import i18n from '../i18n';

import echino from '../img/echino-icon.svg';

import blankFitness from '../img/blank.png';

import fr from '../img/fr.png';
import en from '../img/en.png';
import de from '../img/de.png';
import back from '../img/back.png';

import './CheckoutV2.css';

//Test link
//BQxMlzpyazMyCyybmycPZUpv5bcArqdUf79n76B3LL4S3ExgtoAyDyB1CdDrEntgAWok0ZoM/OkJh0DpGF0k21DMq5VtGPdgXslb1hnOY5VbfpXNmBauVGjggRBaO99nx3EYQxZpq0d8p2vi0PjCz3r7QLOsKjbwRKtEs7rL1Cu3OJf1F3PzSo/ZJiccnhkb7ADiMpGM5DyJS4zpDJJIPGF34ilYgqH4fX27s8SawQ0U+SIysnJo3flQiQWDRub+k5yF0etgFp4OoTZBBRA82xV8hqDEOWkUAABDdCVWmvYuU2jGGGw3nEj4v0cmdtkvrYR+ZcnLsEzNUixd/QuZdinjUye0jW9ySm49odEA0YZQOhg1ZDFg4scjgCXYEFXSeZstLjkCEMBRHiueAeJbIWY06V8d8fNbVdcYa9UUaJunfaaVrxAXCO2BD64LF5zc6QuDUuDvJJZ9bSpx6IzQmGtYNew0QVTU0v3xnA9EcvHEnmPMdi3oW7hvZr35FFIiGoCrUUQpUe+30mKRT01MN2waxxxSt3R7Av8qnGh5vqe+DQMaAl82Kp+o3HMT0X6opLhRyHYEDZw2oG3nYZwIdM96/zwdsluaBP2y6dMCnTwT0IQmqtgsNtjuqafUOgte8K9c0R1CcMMfE6gMUUKxWRi39vaUGfoFQAo3/UYH3iU/w597G7mGKgUgA9Q4c6KqQ4oOXs9JPrlFs6p6Vq+l7XEoXj2UhCd3XDqSNCcd5VWKhgcyyYnaGEwIU/SaHgGqCXuCsaGa34owIRAKLpLH2MsEpb3yfBhi5RImlXSsp/947QqfVlHFGrc+f9rkjLXwNy8UmOidRQunz/Ah9OT746evXt3gRqHedoSVRwCPhWWFg48HObi+ONK3oxm7cimogj0h598VGFSaHS5/ERWXkYYi/xsywtMeqV5sOGiDL//mCFNg6BFgKzjXOT+osfvTq1WRUs0Ug1UgFcM+f7eIJuZMSZ/2/aRycUxI/QjYba+NbwmHy5TaU2xRNCaM2uqT3sAjld17kGzUzKxdE4IlbpTVo6shZAkx8xgxAjA7ixV83dX7yG+g7FnbFVQu5uhQO4bl7B/UYR5DDXBGWkbx7LAGItYHRkZaQn1un6CX4bK1VxRJDWIwICg+HYIOH0Sv
//http://localhost:3000?apikey=BQxMlzpyazMyCyybmycPZUpv5bcArqdUf79n76B3LL4S3ExgtoAyDyB1CdDrEntgAWok0ZoM/OkJh0DpGF0k21DMq5VtGPdgXslb1hnOY5VbfpXNmBauVGjggRBaO99nx3EYQxZpq0d8p2vi0PjCz3r7QLOsKjbwRKtEs7rL1Cu3OJf1F3PzSo/ZJiccnhkb7ADiMpGM5DyJS4zpDJJIPGF34ilYgqH4fX27s8SawQ0U%2BSIysnJo3flQiQWDRub%2Bk5yF0etgFp4OoTZBBRA82xV8hqDEOWkUAABDdCVWmvYuU2jGGGw3nEj4v0cmdtkvrYR%2BZcnLsEzNUixd/QuZdinjUye0jW9ySm49odEA0YZQOhg1ZDFg4scjgCXYEFXSeZstLjkCEMBRHiueAeJbIWY06V8d8fNbVdcYa9UUaJunfaaVrxAXCO2BD64LF5zc6QuDUuDvJJZ9bSpx6IzQmGtYNew0QVTU0v3xnA9EcvHEnmPMdi3oW7hvZr35FFIiGoCrUUQpUe%2B30mKRT01MN2waxxxSt3R7Av8qnGh5vqe%2BDQMaAl82Kp%2Bo3HMT0X6opLhRyHYEDZw2oG3nYZwIdM96/zwdsluaBP2y6dMCnTwT0IQmqtgsNtjuqafUOgte8K9c0R1CcMMfE6gMUUKxWRi39vaUGfoFQAo3/UYH3iU/w597G7mGKgUgA9Q4c6KqQ4oOXs9JPrlFs6p6Vq%2Bl7XEoXj2UhCd3XDqSNCcd5VWKhgcyyYnaGEwIU/SaHgGqCXuCsaGa34owIRAKLpLH2MsEpb3yfBhi5RImlXSsp/947QqfVlHFGrc%2Bf9rkjLXwNy8UmOidRQunz/Ah9OT746evXt3gRqHedoSVRwCPhWWFg48HObi%2BONK3oxm7cimogj0h598VGFSaHS5/ERWXkYYi/xsywtMeqV5sOGiDL//mCFNg6BFgKzjXOT%2BosfvTq1WRUs0Ug1UgFcM%2Bf7eIJuZMSZ/2/aRycUxI/QjYba%2BNbwmHy5TaU2xRNCaM2uqT3sAjld17kGzUzKxdE4IlbpTVo6shZAkx8xgxAjA7ixV83dX7yG%2Bg7FnbFVQu5uhQO4bl7B/UYR5DDXBGWkbx7LAGItYHRkZaQn1un6CX4bK1VxRJDWIwICg%2BHYIOH0Sv&subscription=7adac653-fb72-4bf9-8da2-227fa7a7f974&center=ab8f866e-8d94-4ef3-922a-32535180a881&tc=www.echino.com&redirect=www.echino.com
//https://shop.echino.com?apikey=BQxMlzpyazMyCyybmycPZUpv5bcArqdUf79n76B3LL4S3ExgtoAyDyB1CdDrEntgAWok0ZoM/OkJh0DpGF0k21DMq5VtGPdgXslb1hnOY5VbfpXNmBauVGjggRBaO99nx3EYQxZpq0d8p2vi0PjCz3r7QLOsKjbwRKtEs7rL1Cu3OJf1F3PzSo/ZJiccnhkb7ADiMpGM5DyJS4zpDJJIPGF34ilYgqH4fX27s8SawQ0U%2BSIysnJo3flQiQWDRub%2Bk5yF0etgFp4OoTZBBRA82xV8hqDEOWkUAABDdCVWmvYuU2jGGGw3nEj4v0cmdtkvrYR%2BZcnLsEzNUixd/QuZdinjUye0jW9ySm49odEA0YZQOhg1ZDFg4scjgCXYEFXSeZstLjkCEMBRHiueAeJbIWY06V8d8fNbVdcYa9UUaJunfaaVrxAXCO2BD64LF5zc6QuDUuDvJJZ9bSpx6IzQmGtYNew0QVTU0v3xnA9EcvHEnmPMdi3oW7hvZr35FFIiGoCrUUQpUe%2B30mKRT01MN2waxxxSt3R7Av8qnGh5vqe%2BDQMaAl82Kp%2Bo3HMT0X6opLhRyHYEDZw2oG3nYZwIdM96/zwdsluaBP2y6dMCnTwT0IQmqtgsNtjuqafUOgte8K9c0R1CcMMfE6gMUUKxWRi39vaUGfoFQAo3/UYH3iU/w597G7mGKgUgA9Q4c6KqQ4oOXs9JPrlFs6p6Vq%2Bl7XEoXj2UhCd3XDqSNCcd5VWKhgcyyYnaGEwIU/SaHgGqCXuCsaGa34owIRAKLpLH2MsEpb3yfBhi5RImlXSsp/947QqfVlHFGrc%2Bf9rkjLXwNy8UmOidRQunz/Ah9OT746evXt3gRqHedoSVRwCPhWWFg48HObi%2BONK3oxm7cimogj0h598VGFSaHS5/ERWXkYYi/xsywtMeqV5sOGiDL//mCFNg6BFgKzjXOT%2BosfvTq1WRUs0Ug1UgFcM%2Bf7eIJuZMSZ/2/aRycUxI/QjYba%2BNbwmHy5TaU2xRNCaM2uqT3sAjld17kGzUzKxdE4IlbpTVo6shZAkx8xgxAjA7ixV83dX7yG%2Bg7FnbFVQu5uhQO4bl7B/UYR5DDXBGWkbx7LAGItYHRkZaQn1un6CX4bK1VxRJDWIwICg%2BHYIOH0Sv&subscription=7adac653-fb72-4bf9-8da2-227fa7a7f974&center=ab8f866e-8d94-4ef3-922a-32535180a881&tc=www.echino.com&redirect=www.echino.com


// Dev Db
// q4/bUk8YW1NAKbcBMqAeu/ON5SKLe8eThQnYxajVh/quLj7N0+WcHHc9OR9ri+nSwNqr/OqUPzx/uHwS7499CBgHWnbk2CNQfUivgG0+kYbBsNxbfYlakf35qHk0Al0xDKBlm3LxKRtbaE3e76g+f1V5NRuZIpEtroHsxbBG23epG2fAN84blhcBhTnlxb8sZQViFxd/CLwK1mrFXEwBpDAX2ARwTmmA4gOZnw3p7WXnmOEtSWytd1tt5hxmQlAjKJQFU4UDqxR6B3n1XxHwkLf1FbY37ByPAXsfUD9U4C+Cy5w6xjKkvBLzuPgBVKcmTre8HrkwHSuSBrc3c83tYXMapH2KW4Y74V1cIXjm/BHOM9YhbzGQiM8ZwGzM2B4cSnJIECildw118PTmjypMQB9Lz2DQnHkSg0+81OewvbDjPi80UFEZXYrtbsSBqtXoFTRougfZbnGevLezsDRXnt29SEOzrNQdNlqOnUG8myl3YcOivulM+XjHDfnRfGYo4D/Vjq3WnfRvChAOssbrDowu7DcgoRgepiw7g1pLQE2wWEPUboGeCokvCbpVLyRME+K18isGLAPqqAZelaSUKQoa2LC2qpKkUTIb8diyn4V3jfkzVBt0e2YRtl3zL6sJcM24E8IjmTf6a48IUrZiKgewrnOWeLMA1+Fe94gd4WJuxJDnI0MTkaKU5zCzMA2M1K+AMcyo95N8MuKlRLZDP2yULxoz/HzSxaMr4FiGd4c+4PnZrnxz7kD6Lli4UILfEivecnMJ3TYz0FyMzvvqlxPpdal4rTZUJCqMlYiWCVUU335936VF2fD9K5zzpYgHPGXVXMKYJjY3e5wzhvuc2k7Hy4z5MupbbJbX9ODJfAbhvvi8CbD1U1KAVTWCfjI5QxQXI3aUBWFXXLy/by3kg5k5FmCAOeOnR67Z8MuG1lgrZh2tUgz18NWO3V43Ift29xQmWMy10vVs8CWa/tBQFA7/z6hJaxTaEAc+g+hbwppqQ03Q6U9rtDBGIuEgiA3MGNfyF8N27qkqcvM7hHiy11CIXt4oWnx3NTiaHKQzxddLA0F+e5keQNGp2rZqZC3xSfDMtVhEETYNAsIo4UB/gAhC93pvmcR+4x3Oihsx6gPrn/ptcAV9T7vevPETk2sE
// Autorenew
// http://localhost:3000?apikey=q4/bUk8YW1NAKbcBMqAeu/ON5SKLe8eThQnYxajVh/quLj7N0%2BWcHHc9OR9ri%2BnSwNqr/OqUPzx/uHwS7499CBgHWnbk2CNQfUivgG0%2BkYbBsNxbfYlakf35qHk0Al0xDKBlm3LxKRtbaE3e76g%2Bf1V5NRuZIpEtroHsxbBG23epG2fAN84blhcBhTnlxb8sZQViFxd/CLwK1mrFXEwBpDAX2ARwTmmA4gOZnw3p7WXnmOEtSWytd1tt5hxmQlAjKJQFU4UDqxR6B3n1XxHwkLf1FbY37ByPAXsfUD9U4C%2BCy5w6xjKkvBLzuPgBVKcmTre8HrkwHSuSBrc3c83tYXMapH2KW4Y74V1cIXjm/BHOM9YhbzGQiM8ZwGzM2B4cSnJIECildw118PTmjypMQB9Lz2DQnHkSg0%2B81OewvbDjPi80UFEZXYrtbsSBqtXoFTRougfZbnGevLezsDRXnt29SEOzrNQdNlqOnUG8myl3YcOivulM%2BXjHDfnRfGYo4D/Vjq3WnfRvChAOssbrDowu7DcgoRgepiw7g1pLQE2wWEPUboGeCokvCbpVLyRME%2BK18isGLAPqqAZelaSUKQoa2LC2qpKkUTIb8diyn4V3jfkzVBt0e2YRtl3zL6sJcM24E8IjmTf6a48IUrZiKgewrnOWeLMA1%2BFe94gd4WJuxJDnI0MTkaKU5zCzMA2M1K%2BAMcyo95N8MuKlRLZDP2yULxoz/HzSxaMr4FiGd4c%2B4PnZrnxz7kD6Lli4UILfEivecnMJ3TYz0FyMzvvqlxPpdal4rTZUJCqMlYiWCVUU335936VF2fD9K5zzpYgHPGXVXMKYJjY3e5wzhvuc2k7Hy4z5MupbbJbX9ODJfAbhvvi8CbD1U1KAVTWCfjI5QxQXI3aUBWFXXLy/by3kg5k5FmCAOeOnR67Z8MuG1lgrZh2tUgz18NWO3V43Ift29xQmWMy10vVs8CWa/tBQFA7/z6hJaxTaEAc%2Bg%2BhbwppqQ03Q6U9rtDBGIuEgiA3MGNfyF8N27qkqcvM7hHiy11CIXt4oWnx3NTiaHKQzxddLA0F%2Be5keQNGp2rZqZC3xSfDMtVhEETYNAsIo4UB/gAhC93pvmcR%2B4x3Oihsx6gPrn/ptcAV9T7vevPETk2sE&subscription=60ab883d-619d-4444-b263-eca96af4a299&center=f8ae9de1-ac53-44a8-b0d9-f7cd97ba56dd&tc=www.cgv.com
// No autorenew
// http://localhost:3000?apikey=q4/bUk8YW1NAKbcBMqAeu/ON5SKLe8eThQnYxajVh/quLj7N0%2BWcHHc9OR9ri%2BnSwNqr/OqUPzx/uHwS7499CBgHWnbk2CNQfUivgG0%2BkYbBsNxbfYlakf35qHk0Al0xDKBlm3LxKRtbaE3e76g%2Bf1V5NRuZIpEtroHsxbBG23epG2fAN84blhcBhTnlxb8sZQViFxd/CLwK1mrFXEwBpDAX2ARwTmmA4gOZnw3p7WXnmOEtSWytd1tt5hxmQlAjKJQFU4UDqxR6B3n1XxHwkLf1FbY37ByPAXsfUD9U4C%2BCy5w6xjKkvBLzuPgBVKcmTre8HrkwHSuSBrc3c83tYXMapH2KW4Y74V1cIXjm/BHOM9YhbzGQiM8ZwGzM2B4cSnJIECildw118PTmjypMQB9Lz2DQnHkSg0%2B81OewvbDjPi80UFEZXYrtbsSBqtXoFTRougfZbnGevLezsDRXnt29SEOzrNQdNlqOnUG8myl3YcOivulM%2BXjHDfnRfGYo4D/Vjq3WnfRvChAOssbrDowu7DcgoRgepiw7g1pLQE2wWEPUboGeCokvCbpVLyRME%2BK18isGLAPqqAZelaSUKQoa2LC2qpKkUTIb8diyn4V3jfkzVBt0e2YRtl3zL6sJcM24E8IjmTf6a48IUrZiKgewrnOWeLMA1%2BFe94gd4WJuxJDnI0MTkaKU5zCzMA2M1K%2BAMcyo95N8MuKlRLZDP2yULxoz/HzSxaMr4FiGd4c%2B4PnZrnxz7kD6Lli4UILfEivecnMJ3TYz0FyMzvvqlxPpdal4rTZUJCqMlYiWCVUU335936VF2fD9K5zzpYgHPGXVXMKYJjY3e5wzhvuc2k7Hy4z5MupbbJbX9ODJfAbhvvi8CbD1U1KAVTWCfjI5QxQXI3aUBWFXXLy/by3kg5k5FmCAOeOnR67Z8MuG1lgrZh2tUgz18NWO3V43Ift29xQmWMy10vVs8CWa/tBQFA7/z6hJaxTaEAc%2Bg%2BhbwppqQ03Q6U9rtDBGIuEgiA3MGNfyF8N27qkqcvM7hHiy11CIXt4oWnx3NTiaHKQzxddLA0F%2Be5keQNGp2rZqZC3xSfDMtVhEETYNAsIo4UB/gAhC93pvmcR%2B4x3Oihsx6gPrn/ptcAV9T7vevPETk2sE&subscription=56a535c4-b7fb-4a03-a219-452e18cff390&center=f8ae9de1-ac53-44a8-b0d9-f7cd97ba56dd&tc=www.cgv.com

//budget fitness : ZiaIxhfNGjmPKCXZY3GfAjOvRnz%2BkUW2sdfJbeDIp7mLGv4U5WUhZlx8czZFAmHE/l7HG29QLjtedJWePn8eSAoVhWN5rDqvaEdT/VLm2d3sibSQ53LS7t8%2BUsmvsDC0QD40FpMaU9OSlaylh2GCmN7ITAkqfhnWh/bFdjqd%2B/5iGlfckJM/DliTl%2BmIKdVaebNHjpGPiKhpPV742O4n4k37K/Xt/40YuLTWl3T0GwVvkdgdFghuZxHkuDQ1keDBmVbcBgXOAKTJyXUT/6FNRP5NOi5QUe4ym/jTYM4rIbB%2BbnmpYB3HpgfGpt9nGQRX4liYCJ9dYLqQH5uUP5fsNwWJDWj9L%2BLdSkcMZxKTaAvlgBtyCaagtDGVWRTmFHT7rQ4Dzpd8VYn6MBVNHUpls16firlLTzR4Fn%2B6EuOz/vi8ofkkyY7qlcKvHedpi4TQgVHAZuq5VVh1db1HO1JpOjz9f9F6P%2Bs1jqqrDyk%2B%2Bn2nlfoZ%2BoDctxF4x8vFKToFYyLMUvES2qu2L9jyjE6N1Ndop8oon/gZ3h%2BWYLU6Beyh6Zi8bBY4OMmtTtoMfbWP2tWRD7IT0V6aQRgFHuiQEkZkrGl8zOFYx/e50R4rpIupWjO42jiKvzlbkbdSKPp2Ezvnvzvof3PGJGtbNf1M/mdqGQdPnr1L/9Ue2F1IcPSMOthIra3wPIHG4AnLs0b0pVvv9rsMoRltuY4wJRow7uPOjvZ4V343ErhrJtlhrx67PpMO/UJdk07BUCbK7iQCOKGW6rjt/3V6YgpW5kDTykH0/6mSR4H8SONQpttS3t0sxSz8N2vQVWWKHrChkTRji7lRIh4SdJEj4M8ptnC7ifdyyWpogwiGRTSxdIRnquYtPVXrEL6VJ0qGW1RJaCds71UdRFLnr1vAKom21iHbaQIVpyY64UKoE2kj8kw4NuhQMpz%2BE1AeahfawcqvVurZ4ooIfx0wf/rqu6I72YVH%2BGE/VRPrv5T50Kgy%2Bmo0giWAoewv32EbKfgSvapoSSNF7p6V3lUIqsNdtKbs0jwMr/a7YhaK1rk814of7Cw3VCT3Rj3pEHojJx3Hzfd%2Bi0Bi/rGFiKVAARdPewHKU2NCY7yN%2B1B22FfGlK3/HRmlqD3cvbCpqj8ng/hiKu7hbGgT
//http://localhost:3000?apikey=ZiaIxhfNGjmPKCXZY3GfAjOvRnz%2BkUW2sdfJbeDIp7mLGv4U5WUhZlx8czZFAmHE/l7HG29QLjtedJWePn8eSAoVhWN5rDqvaEdT/VLm2d3sibSQ53LS7t8%2BUsmvsDC0QD40FpMaU9OSlaylh2GCmN7ITAkqfhnWh/bFdjqd%2B/5iGlfckJM/DliTl%2BmIKdVaebNHjpGPiKhpPV742O4n4k37K/Xt/40YuLTWl3T0GwVvkdgdFghuZxHkuDQ1keDBmVbcBgXOAKTJyXUT/6FNRP5NOi5QUe4ym/jTYM4rIbB%2BbnmpYB3HpgfGpt9nGQRX4liYCJ9dYLqQH5uUP5fsNwWJDWj9L%2BLdSkcMZxKTaAvlgBtyCaagtDGVWRTmFHT7rQ4Dzpd8VYn6MBVNHUpls16firlLTzR4Fn%2B6EuOz/vi8ofkkyY7qlcKvHedpi4TQgVHAZuq5VVh1db1HO1JpOjz9f9F6P%2Bs1jqqrDyk%2B%2Bn2nlfoZ%2BoDctxF4x8vFKToFYyLMUvES2qu2L9jyjE6N1Ndop8oon/gZ3h%2BWYLU6Beyh6Zi8bBY4OMmtTtoMfbWP2tWRD7IT0V6aQRgFHuiQEkZkrGl8zOFYx/e50R4rpIupWjO42jiKvzlbkbdSKPp2Ezvnvzvof3PGJGtbNf1M/mdqGQdPnr1L/9Ue2F1IcPSMOthIra3wPIHG4AnLs0b0pVvv9rsMoRltuY4wJRow7uPOjvZ4V343ErhrJtlhrx67PpMO/UJdk07BUCbK7iQCOKGW6rjt/3V6YgpW5kDTykH0/6mSR4H8SONQpttS3t0sxSz8N2vQVWWKHrChkTRji7lRIh4SdJEj4M8ptnC7ifdyyWpogwiGRTSxdIRnquYtPVXrEL6VJ0qGW1RJaCds71UdRFLnr1vAKom21iHbaQIVpyY64UKoE2kj8kw4NuhQMpz%2BE1AeahfawcqvVurZ4ooIfx0wf/rqu6I72YVH%2BGE/VRPrv5T50Kgy%2Bmo0giWAoewv32EbKfgSvapoSSNF7p6V3lUIqsNdtKbs0jwMr/a7YhaK1rk814of7Cw3VCT3Rj3pEHojJx3Hzfd%2Bi0Bi/rGFiKVAARdPewHKU2NCY7yN%2B1B22FfGlK3/HRmlqD3cvbCpqj8ng/hiKu7hbGgT&subscription=ef883625-3f5f-4046-8986-75fafcd90a83&center=ceffa574-dab4-4802-b1b1-8e1070e21080&tc=www.echino.com&redirect=www.echino.com
//http://localhost:3000?apikey=ZiaIxhfNGjmPKCXZY3GfAjOvRnz%2BkUW2sdfJbeDIp7mLGv4U5WUhZlx8czZFAmHE/l7HG29QLjtedJWePn8eSAoVhWN5rDqvaEdT/VLm2d3sibSQ53LS7t8%2BUsmvsDC0QD40FpMaU9OSlaylh2GCmN7ITAkqfhnWh/bFdjqd%2B/5iGlfckJM/DliTl%2BmIKdVaebNHjpGPiKhpPV742O4n4k37K/Xt/40YuLTWl3T0GwVvkdgdFghuZxHkuDQ1keDBmVbcBgXOAKTJyXUT/6FNRP5NOi5QUe4ym/jTYM4rIbB%2BbnmpYB3HpgfGpt9nGQRX4liYCJ9dYLqQH5uUP5fsNwWJDWj9L%2BLdSkcMZxKTaAvlgBtyCaagtDGVWRTmFHT7rQ4Dzpd8VYn6MBVNHUpls16firlLTzR4Fn%2B6EuOz/vi8ofkkyY7qlcKvHedpi4TQgVHAZuq5VVh1db1HO1JpOjz9f9F6P%2Bs1jqqrDyk%2B%2Bn2nlfoZ%2BoDctxF4x8vFKToFYyLMUvES2qu2L9jyjE6N1Ndop8oon/gZ3h%2BWYLU6Beyh6Zi8bBY4OMmtTtoMfbWP2tWRD7IT0V6aQRgFHuiQEkZkrGl8zOFYx/e50R4rpIupWjO42jiKvzlbkbdSKPp2Ezvnvzvof3PGJGtbNf1M/mdqGQdPnr1L/9Ue2F1IcPSMOthIra3wPIHG4AnLs0b0pVvv9rsMoRltuY4wJRow7uPOjvZ4V343ErhrJtlhrx67PpMO/UJdk07BUCbK7iQCOKGW6rjt/3V6YgpW5kDTykH0/6mSR4H8SONQpttS3t0sxSz8N2vQVWWKHrChkTRji7lRIh4SdJEj4M8ptnC7ifdyyWpogwiGRTSxdIRnquYtPVXrEL6VJ0qGW1RJaCds71UdRFLnr1vAKom21iHbaQIVpyY64UKoE2kj8kw4NuhQMpz%2BE1AeahfawcqvVurZ4ooIfx0wf/rqu6I72YVH%2BGE/VRPrv5T50Kgy%2Bmo0giWAoewv32EbKfgSvapoSSNF7p6V3lUIqsNdtKbs0jwMr/a7YhaK1rk814of7Cw3VCT3Rj3pEHojJx3Hzfd%2Bi0Bi/rGFiKVAARdPewHKU2NCY7yN%2B1B22FfGlK3/HRmlqD3cvbCpqj8ng/hiKu7hbGgT&subscription=ef883625-3f5f-4046-8986-75fafcd90a83&tc=www.echino.com&redirect=www.echino.com
//test test 

//echino academy 
//https://shop.echino.com?apikey=%2BEChFcDaYNs9s%2BCi05qrp%2BML6jcIoBjetpibo4HQvutoa%2Bm0HGwg0G5JhQB15axZ1xrJg5iN35IF8zU%2BUi9vywEPN0t2QnWbnIvcawU2BoTzdQQfiSv55UoqHtIIXU14u%2B9g1xGA5xH4ZqAMhMFkAm1Sn%2BGwp%2Bf/OlYGVvLT6qPYTg5KWte5OgJ1Sw5otbazDEIRtkNX8IYT1KIcl/f7sn/HD%2B71BGex1R4yZCC6EKpw3uKCdbgCtGsQ7ZBpIimC1aduz7IB0obv2jupJ0XTVvhBGU5mN2Zx3kUyxsDU4mK1pZcIdHBfKeQ4LhOKg54wZCqkcf/Gou3r8GGSaDAiQSeIXzs5qvvAjj0x51AbJbZr3e3oDflerZdamEf9/22JM7YXqq29CYgEtV5rk1ghBId/4GnVhJzHkEctBTGHAGx2hXjAAYu2gX6vHGlHy0ggiYrReQnOplfHY4beWjb7dnDjmubcsG72%2B6z7izWGdxtZD6mvqawNZdQtrBD4p1paSjT8xw/p/yJDqPf9eDf33x48EDehQiifCHSMKz/MwrrpFHgRyTHdcH%2BWf4LskvsthoMtlFRVaOKsGxyPuzz0eUHwobN%2B%2BIIUbM/ae9MIL8qmN39j1KjBFsmbhQbb1%2BffSU2/s2pJaJE0%2B%2BPmrvpbsaUnvgOARmWnVWQR8P6jBv9xnRmeId0bDW30kZM5l%2BskTXDOCl%2BvEuqGwmjKswuCqZDhYHtwaq7lv8Yd4qmPCOo0CoH1rDDMUnGcbbs4lpqkBK1c%2BFa7fTYbRIgJSR1c5N3krxwG4QUBXzxdlOv3BT7QYV7PgG31bUIli3XQdroRxv0YVMUJzdXRVDcgiF06Twtpnq5iMqDJL4bcMFg1xR4ZnxXEOOaUSTEeRVUVHp030C6UJumg/vHIlYeZN0vPsmicU71iV9BiOFty625zjC%2BjGAVu0WYkZkLuR9A04RfWrB%2BzJJvy0ZeLjlzneUzHDcD8yFNhL3xOIecI%2BrFAghgNPcYAKCxPEpgM9DmpXSmxGvTgnwPrIyoEfEcngv6P0oPJJc1BDlgTEcXYWxbqEQr754GM78RFUuqnMest5lQufhwI3N4ACsQuv8mrB9OxzpRWJGMGj3xZnSx0F6NvyXuJoULrMuliAEPDQRUK85IS&subscription=a42cfe99-3dc7-4bfb-9f1e-1a6d8a7c143b&center=ab8f866e-8d94-4ef3-922a-32535180a881&tc=www.echino.com&redirect=www.echino.com

//all
//http://localhost:3000/?apikey=%2BEChFcDaYNs9s%2BCi05qrp%2BML6jcIoBjetpibo4HQvutoa%2Bm0HGwg0G5JhQB15axZ1xrJg5iN35IF8zU%2BUi9vywEPN0t2QnWbnIvcawU2BoTzdQQfiSv55UoqHtIIXU14u%2B9g1xGA5xH4ZqAMhMFkAm1Sn%2BGwp%2Bf/OlYGVvLT6qPYTg5KWte5OgJ1Sw5otbazDEIRtkNX8IYT1KIcl/f7sn/HD%2B71BGex1R4yZCC6EKpw3uKCdbgCtGsQ7ZBpIimC1aduz7IB0obv2jupJ0XTVvhBGU5mN2Zx3kUyxsDU4mK1pZcIdHBfKeQ4LhOKg54wZCqkcf/Gou3r8GGSaDAiQSeIXzs5qvvAjj0x51AbJbZr3e3oDflerZdamEf9/22JM7YXqq29CYgEtV5rk1ghBId/4GnVhJzHkEctBTGHAGx2hXjAAYu2gX6vHGlHy0ggiYrReQnOplfHY4beWjb7dnDjmubcsG72%2B6z7izWGdxtZD6mvqawNZdQtrBD4p1paSjT8xw/p/yJDqPf9eDf33x48EDehQiifCHSMKz/MwrrpFHgRyTHdcH%2BWf4LskvsthoMtlFRVaOKsGxyPuzz0eUHwobN%2B%2BIIUbM/ae9MIL8qmN39j1KjBFsmbhQbb1%2BffSU2/s2pJaJE0%2B%2BPmrvpbsaUnvgOARmWnVWQR8P6jBv9xnRmeId0bDW30kZM5l%2BskTXDOCl%2BvEuqGwmjKswuCqZDhYHtwaq7lv8Yd4qmPCOo0CoH1rDDMUnGcbbs4lpqkBK1c%2BFa7fTYbRIgJSR1c5N3krxwG4QUBXzxdlOv3BT7QYV7PgG31bUIli3XQdroRxv0YVMUJzdXRVDcgiF06Twtpnq5iMqDJL4bcMFg1xR4ZnxXEOOaUSTEeRVUVHp030C6UJumg/vHIlYeZN0vPsmicU71iV9BiOFty625zjC%2BjGAVu0WYkZkLuR9A04RfWrB%2BzJJvy0ZeLjlzneUzHDcD8yFNhL3xOIecI%2BrFAghgNPcYAKCxPEpgM9DmpXSmxGvTgnwPrIyoEfEcngv6P0oPJJc1BDlgTEcXYWxbqEQr754GM78RFUuqnMest5lQufhwI3N4ACsQuv8mrB9OxzpRWJGMGj3xZnSx0F6NvyXuJoULrMuliAEPDQRUK85IS&subscription=a42cfe99-3dc7-4bfb-9f1e-1a6d8a7c143b&center=ab8f866e-8d94-4ef3-922a-32535180a881&tc=www.echino.com&redirect=www.echino.com
//no center
//http://localhost:3000/?apikey=%2BEChFcDaYNs9s%2BCi05qrp%2BML6jcIoBjetpibo4HQvutoa%2Bm0HGwg0G5JhQB15axZ1xrJg5iN35IF8zU%2BUi9vywEPN0t2QnWbnIvcawU2BoTzdQQfiSv55UoqHtIIXU14u%2B9g1xGA5xH4ZqAMhMFkAm1Sn%2BGwp%2Bf/OlYGVvLT6qPYTg5KWte5OgJ1Sw5otbazDEIRtkNX8IYT1KIcl/f7sn/HD%2B71BGex1R4yZCC6EKpw3uKCdbgCtGsQ7ZBpIimC1aduz7IB0obv2jupJ0XTVvhBGU5mN2Zx3kUyxsDU4mK1pZcIdHBfKeQ4LhOKg54wZCqkcf/Gou3r8GGSaDAiQSeIXzs5qvvAjj0x51AbJbZr3e3oDflerZdamEf9/22JM7YXqq29CYgEtV5rk1ghBId/4GnVhJzHkEctBTGHAGx2hXjAAYu2gX6vHGlHy0ggiYrReQnOplfHY4beWjb7dnDjmubcsG72%2B6z7izWGdxtZD6mvqawNZdQtrBD4p1paSjT8xw/p/yJDqPf9eDf33x48EDehQiifCHSMKz/MwrrpFHgRyTHdcH%2BWf4LskvsthoMtlFRVaOKsGxyPuzz0eUHwobN%2B%2BIIUbM/ae9MIL8qmN39j1KjBFsmbhQbb1%2BffSU2/s2pJaJE0%2B%2BPmrvpbsaUnvgOARmWnVWQR8P6jBv9xnRmeId0bDW30kZM5l%2BskTXDOCl%2BvEuqGwmjKswuCqZDhYHtwaq7lv8Yd4qmPCOo0CoH1rDDMUnGcbbs4lpqkBK1c%2BFa7fTYbRIgJSR1c5N3krxwG4QUBXzxdlOv3BT7QYV7PgG31bUIli3XQdroRxv0YVMUJzdXRVDcgiF06Twtpnq5iMqDJL4bcMFg1xR4ZnxXEOOaUSTEeRVUVHp030C6UJumg/vHIlYeZN0vPsmicU71iV9BiOFty625zjC%2BjGAVu0WYkZkLuR9A04RfWrB%2BzJJvy0ZeLjlzneUzHDcD8yFNhL3xOIecI%2BrFAghgNPcYAKCxPEpgM9DmpXSmxGvTgnwPrIyoEfEcngv6P0oPJJc1BDlgTEcXYWxbqEQr754GM78RFUuqnMest5lQufhwI3N4ACsQuv8mrB9OxzpRWJGMGj3xZnSx0F6NvyXuJoULrMuliAEPDQRUK85IS&subscription=a42cfe99-3dc7-4bfb-9f1e-1a6d8a7c143b&tc=www.echino.com&redirect=www.echino.com

//PWC
//http://localhost:3000?apikey=vR9Bp%2Bg6EiXtH/tJI4VKuSjzz%2BckpCs3lMzIH61vZTxKqg4ujjJtueEYmlcyrLZxQmZpWjIA6p0NsmYU%2ByFNrFAZ/9T19ovUPR3I85IXzW1M7ABH4dLK1G8NgLMdRlm6JBCpwwUZauY/QxpshGUvDfRnlwytaSJmDLggIWxtuFuN5oSC2UUMH%2B8fD/mddcLu4b6gs18WwOJOcfSGBvpbmjNBnxSZsYyuft8uAW20PYfte1U9Dc4qJuG47y17qlCr7RQ7onZjG9hzgwOLo7uPyC5lPcO0vw8HG2cQ/l8aabhogUYtUjID%2BPEtJgotttRSwFJQkVbOPGyt4I0YPAejnWxH3fOvgsxJ6Oy8UJZCltPw%2BvrHHpW98hkG9c8jjPZ91Cq47C5jouu5haaTmAzwL32AGjBA/BTzrATtp581D1ELiJdj5jzDH8T4izhREotwC9X8pEMrXjH2uGtswIsVkX3y/a/IrA1%2B75r%2BC33WKnjyG9pOuuPH0SAYVNJQKXXjoHqmypzSyEqqOH2c2nr2oIbjgzN6aXkKp/25SPV4Vfu1jXJoWHT99I%2BRRnOJ8h/r5N8yCDwblJG6f5bFafeT9RvA8jL85G4JvVbmbTJa6E2PzLs4u5uGAz7m/V9BubwKibShchOHsh98G8A2IXFnbSMppbroyuTei%2BevuRDYIiCoDE2%2B6POhKbI5GBf6pJAQD%2BzzRHwVaMCGh3b4UIaxLearGmV0AyYBmy8dYAsA4TUUNTyDDtYqvnwldE6euBtiJ6MW8rjNs/HTvFlwAjOxwUzVdTHtrsEH28G2QSKr60nLKQVJJxEjeCQ7goU/to14C8MjjDc1D3O5M6wFonpktgbIJzRyTNabMrhEqqKfJiNKAGrB2CbvZmoYlgj4i9icMZQrmPnYo/i9mDIVlmedXQGLFNhK9EFiAKcirWxkNhGZ2M4e80drR%2Bq/yGlgiHIAgijiQgMM073L1twEP6WrlLPJUhqLGavHdhhXbtzZPqkhSmpRCjdpDAJ2crfFN2WYVi5wpd4FlhyoaH%2BqbTIZuiYBPRkuASvKS8ZuIA6fvUD9Vj6MhIrdry67Y7LU3iKiHBVM/0b0APJTbzWYjP5FkNujcXm5B/vU2xKXnAO8GzXaI9C3JtSjKhJIfI9aUrnm&subscription=9282534b-f341-47ac-9abb-6baf23e01d22&center=fb48beaa-33ca-4def-8a68-237519541534
//https://shop.echino.com?apikey=vR9Bp%2Bg6EiXtH/tJI4VKuSjzz%2BckpCs3lMzIH61vZTxKqg4ujjJtueEYmlcyrLZxQmZpWjIA6p0NsmYU%2ByFNrFAZ/9T19ovUPR3I85IXzW1M7ABH4dLK1G8NgLMdRlm6JBCpwwUZauY/QxpshGUvDfRnlwytaSJmDLggIWxtuFuN5oSC2UUMH%2B8fD/mddcLu4b6gs18WwOJOcfSGBvpbmjNBnxSZsYyuft8uAW20PYfte1U9Dc4qJuG47y17qlCr7RQ7onZjG9hzgwOLo7uPyC5lPcO0vw8HG2cQ/l8aabhogUYtUjID%2BPEtJgotttRSwFJQkVbOPGyt4I0YPAejnWxH3fOvgsxJ6Oy8UJZCltPw%2BvrHHpW98hkG9c8jjPZ91Cq47C5jouu5haaTmAzwL32AGjBA/BTzrATtp581D1ELiJdj5jzDH8T4izhREotwC9X8pEMrXjH2uGtswIsVkX3y/a/IrA1%2B75r%2BC33WKnjyG9pOuuPH0SAYVNJQKXXjoHqmypzSyEqqOH2c2nr2oIbjgzN6aXkKp/25SPV4Vfu1jXJoWHT99I%2BRRnOJ8h/r5N8yCDwblJG6f5bFafeT9RvA8jL85G4JvVbmbTJa6E2PzLs4u5uGAz7m/V9BubwKibShchOHsh98G8A2IXFnbSMppbroyuTei%2BevuRDYIiCoDE2%2B6POhKbI5GBf6pJAQD%2BzzRHwVaMCGh3b4UIaxLearGmV0AyYBmy8dYAsA4TUUNTyDDtYqvnwldE6euBtiJ6MW8rjNs/HTvFlwAjOxwUzVdTHtrsEH28G2QSKr60nLKQVJJxEjeCQ7goU/to14C8MjjDc1D3O5M6wFonpktgbIJzRyTNabMrhEqqKfJiNKAGrB2CbvZmoYlgj4i9icMZQrmPnYo/i9mDIVlmedXQGLFNhK9EFiAKcirWxkNhGZ2M4e80drR%2Bq/yGlgiHIAgijiQgMM073L1twEP6WrlLPJUhqLGavHdhhXbtzZPqkhSmpRCjdpDAJ2crfFN2WYVi5wpd4FlhyoaH%2BqbTIZuiYBPRkuASvKS8ZuIA6fvUD9Vj6MhIrdry67Y7LU3iKiHBVM/0b0APJTbzWYjP5FkNujcXm5B/vU2xKXnAO8GzXaI9C3JtSjKhJIfI9aUrnm&subscription=9282534b-f341-47ac-9abb-6baf23e01d22&center=fb48beaa-33ca-4def-8a68-237519541534

//URL Parameters
const queryString = require('query-string');
const urlParams = queryString.parse(window.location.search);
const backendUrl = process.env.REACT_APP_BACKEND_DOMAIN;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

class CheckoutV2 extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            plan: process.env.REACT_APP_PLAN_ID,
            basePrice: process.env.REACT_APP_BASE_PRICE,
            currency: process.env.REACT_APP_CURRENCY,
            connectedAccount: process.env.REACT_APP_CONNECTED_ACOOUNT_ID,
            domain: process.env.BACKEND_DOMAIN,

            //page language
            lang: i18n.language,

            //graphical states
            loading: true,
            error: null,
            validation: false,

            //personal info data
            firstName: '',
            lastName: '',
            email: '',
            city: '',
            countryOrRegion: i18n.t('INPUT_SWITZERLAND'),
            postalCode: '',
            state: '',
            street: '',
            streetNumber: '',

            //contract start data
            contractDate: urlParams.data ? new Date(urlParams.data) : new Date().toISOString().slice(0, 10),

            //wether the center was specified or not
            noCenter: false,
            //list of all centers (only name and id)
            centerList: [],
            //center data
            center: urlParams.center,
            //center name
            centerName: i18n.t('INPUT_BASE_CENTER_NAME'),
            //center logo (loaded onCompDidMount)
            centerLogo: null,

            //Given subscription
            subscriptionId: urlParams.subscription || "",
            //Subscription name loaded async
            subscriptionName: ''
        };
    }

    //#region language selection
    async changeLanguage(lng) {
        //Change the "Switzerland" text in the state depending the language choise
        var currentLangSwitzerland = i18n.t('INPUT_SWITZERLAND');

        //call language change
        await i18n.changeLanguage(lng);
        //update state
        this.setState({ lang: lng });

        //if the person specify another country doesnt change the field
        if (this.state.countryOrRegion === currentLangSwitzerland) {
            this.setState({ countryOrRegion: i18n.t('INPUT_SWITZERLAND') });
        }
    }
    //#endregion

    //#region input changes
    //Basically set states for every input field
    //Personal data
    handleFirstNameInput = (e) => {
        this.setState({ firstName: e.target.value });
    }
    handleLastNameInput = (e) => {
        this.setState({ lastName: e.target.value });
    }

    //Contact data
    handleEmailInput = (e) => {
        this.setState({ email: e.target.value });
    }

    //Address
    handleStreetInput = (e) => {
        this.setState({ street: e.target.value });
    }

    handleStreetNumberInput = (e) => {
        this.setState({ streetNumber: e.target.value });
    }

    handleCityInput = (e) => {
        this.setState({ city: e.target.value });
    }

    handlePostalCodeInput = (e) => {
        this.setState({ postalCode: e.target.value });
    }

    // State & Country
    handleCountryOrRegionInput = (e) => {
        this.setState({ countryOrRegion: e.target.value });
    }

    handleStateInput = (e) => {
        this.setState({ state: e.target.value });
    }

    //Date
    handleDateChange = (e) => {
        if (e.target.value) {
            this.setState({ contractDate: e.target.value });
        }
    }

    //Center
    handleSelectChange = (e) => {
        this.setState({ center: e.target.value });

        this.isCenterComplete();
    }
    //#endregion

    //#region validators
    isNameComplete() {
        return ((this.state.firstName !== '') && (this.state.lastName !== ''));
    }

    isEmailComplete() {
        var email = this.state.email;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var response = re.test(String(email).toLowerCase());
        return response;

    }

    isAddressComplete() {
        return (this.state.city !== '' &&
            this.state.countryOrRegion !== '' &&
            this.state.postalCode !== '' &&
            this.state.state !== '' && this.state.street !== '')
    }

    isCenterComplete() {
        return (this.state.center !== '' && this.state.center !== undefined)
    }

    /**This predicate should check any circumstance that makes proceeding with payment or signing contract impossible*/
    canSubmit() {
        var rc;
        rc = this.isNameComplete() && this.isAddressComplete() && this.isCenterComplete() && (this.state.email && this.isEmailComplete(this.state.email));
        return rc;
    }

    getSubscriptionMaxDate() {
        return new Date((new Date().getFullYear()) + 1, new Date().getMonth(), new Date().getDate());
    }
    //#endregion

    //#region Terms and condition component
    TermsConditions(tc, t) {
        /*if (tc.tc !== undefined) {
            var uri = decodeURI(tc.tc);
            uri += (uri.split('?')[1] ? '&' : '?') + "lang=" + this.state.lang;
            console.log(uri);
            return <a href={uri}><h4><b>{t("By clicking this you accept terms and conditions of Echino Fitness platform")}</b></h4></a>;
        }
        else return <h4></h4>;*/
    }
    //#endregion

    sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    //???
    getCheckoutSessionRequestBody() {

        let stateBuffer = this.state
        delete stateBuffer.centerLogo
        delete stateBuffer.error
        delete stateBuffer.stripeButtonPressed
        delete stateBuffer.centerList

        //This condition handles situation, if user doesnt click on calendar, but date is provided by parameter
        if ((!stateBuffer.contractDate) && (stateBuffer.contractDateSelected))
            stateBuffer.contractDate = stateBuffer.contractDateSelected.toLocaleDateString()

        const otherThanAppStateAttrubutes = {
            apiKey: urlParams.apikey,
            callback_success: urlParams.callback_success || "",
            callback_failed: urlParams.callback_failed || "",
            redirect: urlParams.redirect || "",
        }

        //Object concatenation
        const body = Object.assign(stateBuffer, otherThanAppStateAttrubutes, { locale: stateBuffer.lang })

        console.log('request body');
        console.log(body);
        return body
    }


    //Validate & pay
    Pay = async () => {
        this.setState({ loading: true, validation: true });

        if (this.canSubmit() === true) {

            const sessionIdServiceAddress = `${backendUrl}/session_checkout`

            const sessionIdResponse = await fetch(sessionIdServiceAddress, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(this.getCheckoutSessionRequestBody()),
            }).then(function (result) {
                return result.json();
            });

            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({
                sessionId: sessionIdResponse.sessionId
            });

            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer
            // using `error.message`.
            if (error) {
                //dispatch({ type: 'setError', payload: { error } });
                this.setState({ loading: false })
            }
        }
        else {
            this.setState({ loading: false })
        }
    };

    //Component load
    async componentDidMount() {

        if (this.state.center !== null && this.state.center !== '' && this.state.center !== undefined) {
            const centerEndPoint = `${backendUrl}/centers/` + this.state.center;
            await fetch(centerEndPoint, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    apikey: urlParams.apikey
                }
            })
            .then(result => result.json())
            .then((result) => {
                if(result[0] === null)
                    throw new Error('OrgId is either false or node group');

                this.setState({ centerName: result[0].centerName, centerLogo: result[0].logo })

            }).catch((error) => {
                console.error('[ERROR]::' + error);
                this.setState({ error:error });
            });

            
        } else {
            const centersEndPoint = `${backendUrl}/centers/`;

            var centersResponse = await fetch(centersEndPoint, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    apikey: urlParams.apikey
                }
            }).then(result => result.json()
            ).catch((error) => {
                console.log('error ' + error);
            });


            var centers = centersResponse.map(function (center) {
                
                return { id: center.id, name: center.centerName, logo: center.logo };
            });

            


            if (centers.length > 1) {
                var centerList = centers.map(function (center) {
                    return { id: center.id, name: center.name };
                });
                this.setState({ noCenter: true, centerList: centerList })
            }
            else {
                console.log('center-unique '+centers[0].name)
                this.setState({ center: centers[0].id, centerName: centers[0].name, centerLogo: centers[0].logo })
            }
        }

        if (this.state.subscriptionId !== null && this.state.subscriptionId !== '') {
            const subscriptionEndPoint = `${backendUrl}/subscription/` + this.state.subscriptionId;
            
            var subscription = await fetch(subscriptionEndPoint, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    apikey: urlParams.apikey
                }
            }).then(result => result.json()
            ).catch((error) => {
                console.log('error ' + error);
            });

            var subscriptionName = "";

            if (subscription.name.substr(0, 1) === "{") {
                subscriptionName = JSON.parse(subscription.name);
                var currentLanguageSubName = subscriptionName[i18n.language.slice(0, 2)];

                if (currentLanguageSubName) {
                    this.setState({ subscriptionName: currentLanguageSubName })
                }
                else if (subscriptionName['fr'] !== '') {
                    this.setState({ subscriptionName: subscriptionName['fr'] })
                }
                else if (subscriptionName['de'] !== '') {
                    this.setState({ subscriptionName: subscriptionName['de'] })
                }
                else if (subscriptionName['en'] !== '') {
                    this.setState({ subscriptionName: subscriptionName['en'] })
                }
            }

            else{
                subscriptionName = subscription.name;
                this.setState({ subscriptionName: subscriptionName })
            }

        }

        if (this.state.lang.length > 2) {
            let ISOLang = this.state.lang.substr(0, 2);

            if (ISOLang !== 'fr' && ISOLang !== 'en' && ISOLang !== 'de') {
                console.log('unknown language ' + this.state.lang + ' changing to english');
                this.changeLanguage('en')
            }
            else {
                this.changeLanguage(ISOLang)
            }
        }

        if(this.state.error === null){
            await this.sleep(1000);

            this.setState({ loading: false })
        }
        else{
            console.error('Error during loading...')
        }
    }

    //#region HTML Render
    render() {
        const { t } = this.props;

        return (
            <div className={`checkout ${this.state.loading ? 'loading' : ''} ${this.state.paymentLoading ? 'loading' : ''}`}>
                <div className="left">
                    <div className="left-content">

                        <div className="lang">
                            <img className={this.state.lang === "fr" ? 'selected' : ''} alt={t("INPUT_LANG_FRENCH")} width="22" height="15" src={fr} onClick={() => this.changeLanguage('fr')} />
                            <img className={this.state.lang === "de" ? 'selected' : ''} alt={t("INPUT_LANG_GERMAN")} width="22" height="15" src={de} onClick={() => this.changeLanguage('de')} />
                            <img className={this.state.lang === "en" ? 'selected' : ''} alt={t("INPUT_LANG_ENGLISH")} width="22" height="15" src={en} onClick={() => this.changeLanguage('en')} />
                        </div>

                        <div className="title">
                            <img alt={t("INPUT_BROWSER_BACK")} width="11" height="10" src={back} onClick={() => window.history.back()} />
                            <div className="content">{this.state.centerName}</div>
                            <div className="subtitle">{this.state.subscriptionName}</div>
                        </div>

                        <div id="mobile-logo" className={this.state.noCenter === true ? 'holding' : ''} style={{ backgroundImage: `url('${this.state.centerLogo ? 'data:image/png;base64, ' + this.state.centerLogo : blankFitness}')` }}></div>
                        <div id="logo" className={this.state.noCenter === true ? 'holding' : ''} style={{ backgroundImage: `url('${this.state.centerLogo ? 'data:image/png;base64, ' + this.state.centerLogo : blankFitness}')` }} />

                        <div className="info">
                            Powered by <a rel="noopener noreferrer" target="_blank" href="https://echino.com">echino</a>
                        </div>

                    </div>

                </div>
                <div className="right">
                    <div className="right-content">
                        <h4>{t("INPUT_DATE_DATA")}</h4>
                        <div className="input-container">
                            <input type="date"
                                value={this.state.contractDate}
                                onChange={this.handleDateChange}
                                min={new Date().toISOString().slice(0, 10)}
                                max={this.getSubscriptionMaxDate().toISOString().slice(0, 10)} />
                            <span className="placeholder">{t("INPUT_DATE_BEGIN")}</span>
                        </div>

                        <h4>{t("INPUT_LOCATION_DATA")}</h4>
                        {!this.isCenterComplete() && this.state.validation ? <h5>{t("INPUT_FILED_REQUIRED")}</h5> : ''}

                        <div className={`input-container ${!this.isCenterComplete() && this.state.validation ? 'error' : ''} ${!this.state.noCenter ? 'disabled' : ''}`}>
                            {!this.state.noCenter
                                ? <label>{this.state.centerName}</label>
                                : <select defaultValue='' onChange={this.handleSelectChange}>
                                    <option value='' disabled hidden>{t("INPUT_SELECT_CENTER")}</option>
                                    {this.state.centerList.map((center, index) => {
                                        return <option key={index} value={center.id}>{center.name}</option>
                                    })}
                                </select>}

                            <span className="placeholder">{t("INPUT_CENTER")}</span>
                        </div>


                        <h4>{t("INPUT_PERSONNAL_DATA")}</h4>
                        {!this.isNameComplete() && this.state.validation ? <h5>{t("INPUT_FILED_REQUIRED")}</h5> : ''}

                        <div className={`input-container ${!this.isNameComplete() && this.state.validation ? 'error' : ''}`}>
                            <input type="text" value={this.state.firstName} onChange={this.handleFirstNameInput} />
                            <span className="placeholder">{t("INPUT_FIRSTNAME")}</span>
                        </div>
                        <div className={`input-container ${!this.isNameComplete() && this.state.validation ? 'error' : ''}`}>
                            <input type="text" value={this.state.lastName} onChange={this.handleLastNameInput} />
                            <span className="placeholder">{t("INPUT_LASTNAME")}</span>
                        </div>

                        <h4>{t("INPUT_CONTACT_DATA")}</h4>
                        {this.state.email === '' && this.state.validation ? <h5>{t("INPUT_FILED_REQUIRED")}</h5> : !this.isEmailComplete() && this.state.validation ? <h5>{t("INPUT_NOT_VALID_EMAIL")}</h5> : ''}

                        <div className={`input-container ${(this.state.email === '' || !this.isEmailComplete()) && this.state.validation ? 'error' : ''}`}>
                            <input type="email" value={this.state.email} onChange={this.handleEmailInput} />
                            <span className="placeholder">{t("INPUT_MAIL")}</span>
                        </div>

                        <h4>{t("INPUT_ADDRESS_DATA")}</h4>
                        {!this.isAddressComplete() && this.state.validation
                            ? <h5>{t("INPUT_FILED_REQUIRED")}</h5> : ''}

                        <div className="address-block">
                            <div className={`input-container upper-left ${!this.isAddressComplete() && this.state.validation ? 'error' : ''}`}>
                                <input type="text" value={this.state.street} onChange={this.handleStreetInput} />
                                <span className="placeholder">{t("INPUT_STREET")}</span>
                            </div>
                            <div className={`input-container upper-right ${!this.isAddressComplete() && this.state.validation ? 'error' : ''}`}>
                                <input type="text" value={this.state.streetNumber} onChange={this.handleStreetNumberInput} />
                                <span className="placeholder">{t("INPUT_STREET_NUMBER")}</span>
                            </div>
                            <div className={`input-container lower-left ${!this.isAddressComplete() && this.state.validation ? 'error' : ''}`}>
                                <input type="text" value={this.state.city} onChange={this.handleCityInput} />
                                <span className="placeholder">{t("INPUT_CITY")}</span>
                            </div>
                            <div className={`input-container lower-right ${!this.isAddressComplete() && this.state.validation ? 'error' : ''}`}>
                                <input type="text" value={this.state.postalCode} onChange={this.handlePostalCodeInput} />
                                <span className="placeholder">{t("INPUT_POSTAL_CODE")}</span>
                            </div>
                        </div>

                        <div className={`input-container ${!this.isAddressComplete() && this.state.validation ? 'error' : ''}`}>
                            <input type="text" value={this.state.state} onChange={this.handleStateInput} />
                            <span className="placeholder">{t("INPUT_STATE")}</span>
                        </div>
                        <div className={`input-container ${!this.isAddressComplete() && this.state.validation ? 'error' : ''}`}>
                            <input type="text" value={this.state.countryOrRegion} onChange={this.handleCountryOrRegionInput} />
                            <span className="placeholder">{t("INPUT_COUNTRY")}</span>
                        </div>

                        {this.TermsConditions(urlParams.tc, t)}

                        <button className="pay-btn" onClick={this.Pay}>{this.state.paymentLoading ? '...' : t("INPUT_PAY")}</button>
                    </div>
                </div>
                <div id="loading-container">
                    <div id="loading" className={`${this.state.loading ? 'show' : ''} ${this.state.paymentLoading ? 'show' : ''}`}>
                        <svg width="100" height="100" overflow="visible">
                            <defs>
                                <mask id="loading-mask1">
                                    <g id="loading-mask-content">
                                        <rect fill="white" width="120" height="120" transform="translate(-60 -60)" />
                                        <rect fill="black" width="74" height="74" rx="30" transform="translate(-37 -37)" />
                                    </g>
                                </mask>

                                <mask id="loading-mask2">
                                    <g>
                                        <rect fill="white" width="120" height="120" transform="translate(-60 -60)" />
                                        <circle fill="black" cx="50" cy="50" r="40" transform="translate(-50 -50)" />
                                    </g>
                                </mask>
                            </defs>
                            <g transform="translate(50 50)">
                                <g id="wave2">
                                    <rect width="90" height="90" rx="35" fill="#13AFCA" opacity="0.5" transform="translate(-45 -45)" />
                                </g>
                                <g id="wave1">
                                    <rect width="80" height="80" rx="30" fill="#13AFCA" transform="translate(-40 -40)" />
                                </g>

                            </g>

                        </svg>
                        <img id="echino-icon" alt="echino icon" src={echino} height="50" width="35" />
                    </div>
                </div>

            </div>
        );
    }
    //#endregion
}

export const Checkout = withTranslation()(CheckoutV2)